import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    isHeaderHidden: false,
    user_data: {
        is_verified: false,
        country_code: "IN",
        access_token: "",
        name: "",
    },
    user_validate: {},
    campus_data: {
        campus_id: "",
        campus_name: "",
        course: "",
        division: "",
        student_pk: "",
    },
    tefunData: {
        isLoading: false,
        isStarted: false,
        // currentTopic: "",
        examStartTime: null,
        examScheduled: null,
        examEndTime: null,
        examCompleted: null,
        examStarted: null,
        courseCompleted: null,
        isStudentExists: false,
        certificateId: null,
        selected_course: "",
    },
    error: "",
    errorState: {
        isError: false,
        errorData: {
            status: 200,
            redirectLink: "",
            errorMessage: "An error occured, please try later",
        },
    },
    currentTopic: "",
    country_details: {
        country: {
            name: "India",
            web_code: "IN",
            country_code: "IND",
            flag: "https://ddl0zi5h2jlue.cloudfront.net/media/countries/flags/download.png",
            phone_code: "+91",
        },
    },
    students: [],
    search_results: [],
    //   isFilter: true,
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
