import "./assets/css/style.css";
import React, { useContext, useEffect } from "react";
import Store, { Context } from "./components/context/store";
import MainRouter from "./components/routing/routers/MainRouter";
import withClearCache from "./ClearCache";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp() {
    return (
        <Store>
            <MainRouter />
        </Store>
    );
}

export default App;
