import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SpotLight from "../../web/screens/Spotlight";
import StartupSchoolSpotlight from "../../web/screens/StartupSchoolSpotlight";
import WhatIsStartupSchool from "../../web/screens/WhatIsStartupSchool";
import LandingPage from "../../web/screens/LandingPage.jsx";

function AuthRouter() {
    return (
        <>
            <Suspense>
                {/* <CoverPage /> */}
                <Routes>
                    <Route>
                        <Route path="" element={<LandingPage />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default AuthRouter;
