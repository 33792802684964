import React, { useState, useEffect } from "react";
import styled from "styled-components";
// --------------images
import BackImage from "../../../assets/images/technology-landing/background.png";
import FlagImage from "../../../assets/images/technology-landing/flag.svg";
import TickCircle from "../../../assets/images/technology-landing/tick-circle.svg";
import TickCircleGreen from "../../../assets/images/technology-landing/tick-green.svg";
import SpotTick from "../../../assets/images/technology-landing/spot-arrow.svg";
import newsImage from "../../../assets/images/technology-landing/24-image.svg";
import playImage from "../../../assets/images/technology-landing/play-image.png";
// import Techies from "../../../assets/images/technology-landing/techies-park.svg";
import TechBack from "../../../assets/images/technology-landing/spot-design.svg";

import JoinNowModal from "../../tefun/modals/JoinNowModal";
import CountrySelector from "../../tefun/modals/CountrySelector";
import { Link, useSearchParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import Header from "../../includes/Header";
import ModalCover from "../../../general/login/ModalCover";
// import SpotlightVideoModal from "../../includes/modal/SpotlightVideoModal";
// import VideoModal from "../../includes/modal/VideoModal";
import CallImage from "../../../assets/images/technology-landing/Call.svg";
import CallingImage from "../../../assets/images/technology-landing/calling.svg";
import WhatImage from "../../../assets/images/technology-landing/whatsapp.png";
import CloseImage from "../../../assets/images/technology-landing/closegreen.svg";
import FormSuccesModal from "../../tefun/modals/FormSuccesModal";

const StartupSchoolSpotlight = () => {
    Aos.init();
    const [code, setCode] = useState(false);
    const [selected, setSelected] = useState({
        country_code: "IND",
        flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
        name: "India",
        phone_code: "+91",
        phone_number_length: 10,
        web_code: "IN",
    });
    const [country, setCountry] = useState("");
    const [isVideoModal, setVideoModal] = useState(false);
    const [isModal, setModal] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isBubble, setBubble] = useState(false);
    const action = searchParams.get("action");


    const submitNumber = (e) => {
        e.preventDefault();
        if (number.length === 10) {
            setFormModal(true);
            setError(false);
        } else {
            setError(true);
        }
    };
    const handleNum = (e) => {
        if (number.length <= 10) {
            setNumber(e.target.value);
        }
    };
    const validate = (e) => {
        window.addEventListener(
            "keydown",
            function (e) {
                if (["ArrowUp", "ArrowDown"].indexOf(e.code) > -1) {
                    e.preventDefault();
                }
            },
            false
        );
    };
    useEffect(() => {
        if (formModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [formModal]);
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    // useEffect(() => {
    //     if (successModal) {
    //         $("html").addClass("modal-enabled");
    //     } else {
    //         $("html").removeClass("modal-enabled");
    //     }
    // }, [successModal]);

    return (
        <>
            <Header />
            <Cover id="home">
                <Wrapper className="wrappertg">
                    <BackCover BackImage={BackImage}>
                        <LeftSection id="technology-form">
                            <Heading>
                                <h4>
                                    Talrop’s <br />
                                    <Green>Startup School</Green>
                                </h4>
                            </Heading>
                            <SubHead>
                                Startup School trains students from scratch and
                                provides support to build their own startup
                                along with giving them an opportunity to gain
                                hands-on experience through various management
                                tasks.
                            </SubHead>
                            <FormSection>
                                <FormCover>
                                    <LeftFlag onClick={() => setCode(!code)}>
                                        <ImageCont>
                                            <img
                                                src={selected.flag}
                                                alt="flag"
                                            />
                                        </ImageCont>
                                        <Code>{selected.phone_code}</Code>
                                    </LeftFlag>
                                    <input
                                        type="number"
                                        placeholder="Enter phone number"
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                        value={number}
                                        maxLength={10}
                                        onKeyDown={(e) => {
                                            [
                                                "e",
                                                "E",
                                                ".",
                                                "+",
                                                "-",
                                                "/",
                                                "&",
                                                "^",
                                                "(",
                                                ")",
                                                "_",
                                                "%",
                                                "#",
                                                "@",
                                                "!",
                                                "~",
                                            ].includes(e.key) &&
                                                e.preventDefault();
                                        }}
                                        onKeyPress={(e) => validate(e)}
                                    />

                                    <input
                                        className="button"
                                        type="submit"
                                        value="Join Now"
                                        onClick={(e) => submitNumber(e)}
                                    />
                                    <TickContainer>
                                        <img src={SpotTick} alt="Tick" />
                                    </TickContainer>
                                </FormCover>
                                {error && (
                                    <Errormsg>Enter a valid number</Errormsg>
                                )}
                            </FormSection>
                        </LeftSection>
                        <RightSection>
                            <TopImage>
                                <img
                                    src={require("../../../assets/images/technology-landing/SpotlightImage.png")}
                                    alt="image"
                                />
                            </TopImage>
                        </RightSection>
                    </BackCover>
                </Wrapper>
            </Cover>
            {formModal && (
                <JoinNowModal
                    formModal={formModal}
                    setFormModal={setFormModal}
                    number={number}
                    selected={selected}
                    setSelected={setSelected}
                    setModal={setModal}
                    setNumber={setNumber}
                    isModal={isModal}
                    name={name}
                    setName={setName}
                    setCountry={setCountry}
                    country={country}
                />
            )}

            <CountrySelector
                selected={selected}
                setSelected={setSelected}
                code={code}
                setCode={setCode}
                setCountry={setCountry}
            />
            {action ? <ModalCover /> : null}
        </>
    );
};

export default StartupSchoolSpotlight;

const Cover = styled.div`
    // min-height:100vh;
    padding-top: 140px;
    overflow: hidden;
    position: relative;
`;
const Wrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const BackCover = styled.div`
    // height:90vh;
    border-radius: 20px;
    background-image: ${({ BackImage }) => `url(${BackImage})`};
    background-color: #f9faf8;
    background-size: cover;
    padding: 50px 60px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1080px) {
        flex-wrap: wrap;
    }
    @media (max-width: 640px) {
        @media (max-width: 640px) {
            padding: 28px;
        }
    }
    @media (max-width: 640px) {
        padding: 28px 10px;
    }
`;
const LeftSection = styled.div`
    width: 45%;
    padding: 60px 0px;
    @media (max-width: 1080px) {
        width: 100%;
    }
    @media (max-width: 980px) {
        padding: 0 0 60px 0;
    }
`;

const Heading = styled.div`
    h4 {
        font-size: 45px;
        font-family: gordita_medium;
        color: #212121;
        @media (max-width: 980px) {
            font-size: 40px;
        }
        @media (max-width: 640px) {
            font-size: 30px;
        }
        @media (max-width: 480px) {
            font-size: 25px;
        }
    }
`;
const Green = styled.h4`
    color: #0fa76f !important;
`;
const SubHead = styled.p`
    margin-top: 15px;
    font-size: 15px;
`;
const FormSection = styled.form``;
const FormCover = styled.div`
    width: 70%;
    padding: 5px;
    background: #fff;
    border: 1px solid #d2e4de;
    border-radius: 10px;
    display: flex;
    margin-top: 30px;
    position: relative;
    align-items: center;
    @media (max-width: 1280px) {
        width: 95%;
    }
    @media (max-width: 1080px) {
        width: 55%;
    }
    @media (max-width: 980px) {
        width: 70%;
    }
    @media (max-width: 768px) {
        width: 75%;
    }
    @media (max-width: 640px) {
        width: 75%;
    }
    @media (max-width: 420px) {
        width: 100%;
    }
    input {
        width: 55%;
        // background:red;
        margin-left: 5px;
        font-size: 14px;
        color: #000;
        ::placeholder {
            @media (max-width: 480px) {
                font-size: 13px;
            }
        }
        &.button {
            background: #0fa674;
            font-family: "gordita_medium";
            width: 30%;
            border-radius: 3px;
            height: 40px;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            @media (max-width: 640px) {
                font-size: 12px;
                height: 35px;
            }
            @media (max-width: 360px) {
                font-size: 11px;
            }
        }
    }
`;
const LeftFlag = styled.div`
    display: flex;
    // width: 17%;
    justify-content: space-between;
    align-items: center;
    // margin-right:5px;
    cursor: pointer;
    @media (max-width: 480px) {
        width: 20%;
    }
    @media (max-width: 320px) {
        width: 20%;
    }
`;
const ImageCont = styled.div`
    width: 30px;
    height: 30px;
    // background: green;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
    @media (max-width: 640px) {
        width: 20px;
        height: 20px;
    }
    img {
        width: 150%;
        display: block;
        object-fit: contain;
        transform: translateX(-8px);
        @media (max-width: 640px) {
            transform: translateX(-5px);
        }
    }
`;
const Code = styled.div`
    font-size: 14px;
    color: #000;
    @media (max-width: 640px) {
        font-size: 13px;
        margin-top: 3px;
    }
`;
const Container = styled.div`
    margin-top: 30px;
`;
const SectionLine = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;
const RectLeft = styled.div`
    width: 40px;
    height: 40px;
    background: #e9f7f2;
    // padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-right: 10px;
    img {
        width: 100%;
    }
`;
const Descript = styled.h5`
    width: calc(100% - 50px);
    font-size: 18px;
    color: #212121;
    font-family: gordita_medium;
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const RightSection = styled.div`
    position: relative;
    width: 50%;
    border-radius: 10px;
    @media (max-width: 1080px) {
        width: 65%;
        margin: 0 auto;
    }
    @media (max-width: 768px) {
        width: 85%;
        margin: 0 auto 20px;
    }
`;
const TopImage = styled.div`
    width: 100%;
    /* z-index: 9;
    border-radius: 10px;
    overflow: hidden;
    transform: translate(30px, -30px);
    position: relative; */
    img {
        width: 100%;
        display: block;
    }
    @media (max-width: 640px) {
        transform: translate(-16px, -15px);
    }
`;
const DesignImage = styled.div`
    width: 80%;
    position: absolute;
    left: 10%;
    top: 12%;
    img {
        width: 100%;
    }
    @media (max-width: 1080px) {
        // position:inherit;
        width: 65%;
    }
`;
const TickContainer = styled.div`
    position: absolute;
    top: -30px;
    right: -60px;
    width: 50px;
    height: 50px;
    & img {
        width: 100%;
        display: block;
    }
    @media (max-width: 420px) {
        /* display: none; */
        top: -60px;
        right: 5px;
        width: 35px;
    }
`;
const Errormsg = styled.h4`
    color: red;
    font-size: 13px;
    margin-top: 5px;
`;
const Play = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ff6446;
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
        width: 100%;
        display: block;
    }
`;
const Bubble = styled.div`
    width: 150px;
    height: 50px;
    // border-radius:50%;
    // background: linear-gradient(180deg, #0FA76F 0%, #0B6846 100%);
    position: fixed;
    bottom: 10%;
    right: 10%;
    z-index: 11;
    // padding:15px;
`;
const CallBubble = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 10;
    transition: 0.4s ease;
    &.active {
        // transform: translateX(-120px);
        right: 80%;
        transition: 0.4s ease;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const WhatsBubble = styled.a`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    &.active {
        // transform:translateX(-60px);
        right: 40%;
        transition: 0.4s ease;
    }
    &.call-active {
        right: 80%;
        transition: 0.4s ease;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const CloseBubble = styled.div`
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    img {
        width: 100%;
        display: block;
    }
`;

// const Container = styled.section``
