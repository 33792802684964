import React, { useEffect, useState, memo } from "react";

//  Packages
import Slider from "react-slick";
import styled from "styled-components";
import $ from "jquery";
import { Link } from "react-scroll";
// import $ from 'jQuery';
import { manageConfig } from "../../helpers/axiosConfig";
// import TestimonialVideo from "../../includes/modal/TestimonialVideo";
// import FormModal from "../../includes/modal/FormModal";
import UnderLine from "../../../assets/images/technology-landing/underline.png";
import rectangleImage from "../../../assets/images/technology-landing/rectangle-image.png";
import AOS from "aos";
import "aos/dist/aos.css";
import TestimonialVideo from "../../tefun/modals/TestimonialVideo";

const NewTestimonial = () => {
    const [employee, setEmployee] = useState([]);
    const [activeTest, setActiveTest] = useState("");
    const [isModal, setModal] = useState(false);
    const [isForm, setForm] = useState(false);
    AOS.init();
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <ArrowNext
                className={className}
                // style={{  }}
                onClick={onClick}
            >
                Next
            </ArrowNext>
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <ArrowPrev
                className={className}
                // style={{  }}
                onClick={onClick}
            >
                Next
            </ArrowPrev>
        );
    }
    //slick settings
    const settings = {
        autoplay: false,
        dots: false,
        infinite: true,
        autoplaySpeed: 500,
        lazyLoad: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    initialSlide: 1,

                    // dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                },
            },
        ],
    };
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    useEffect(() => {
        manageConfig
            .get("technology-schools/employee/testimonial/")
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setEmployee(data);
                } else {
                    setEmployee(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            {/* <FormModal isForm={isForm} setForm={setForm} /> */}
            <Cover id="testimonials">
                <Wraper className="wrappertg">
                    <TopSection>
                        <Header>
                            <span>
                                Testimonials{" "}
                                <UnderImage>
                                    <img src={UnderLine} alt="image" />
                                </UnderImage>
                            </span>
                        </Header>
                    </TopSection>
                    <BottomSect data-aos="fade-up">
                        <RectDesign>
                            <img src={rectangleImage} alt="image" />
                        </RectDesign>
                        <Testmonials id="test">
                            <Slider {...settings}>
                                {employee.map((item) => (
                                    <Widgets
                                        key={item.id}
                                        onClick={() => {
                                            setActiveTest(item.video_url);
                                            setModal(!isModal);
                                        }}
                                    >
                                        <ImageContainer>
                                            <Overlay></Overlay>
                                            <img src={item.photo} alt="image" />
                                            <PlayIcon>
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-06-2022/playImage.svg"
                                                    alt="image"
                                                />
                                            </PlayIcon>
                                        </ImageContainer>
                                        <UserDtail>
                                            <Name>{item.name}</Name>
                                            <Designation>
                                                {item.designation}
                                            </Designation>
                                        </UserDtail>
                                    </Widgets>
                                ))}
                            </Slider>
                        </Testmonials>
                    </BottomSect>
                    {/*<ButtonSection>
                        <Button to="home" smooth={true}>
                            Apply Now
                        </Button>
                                    </ButtonSection>*/}
                </Wraper>
                {isModal && (
                    <TestimonialVideo
                        isModal={isModal}
                        setModal={setModal}
                        activeTest={activeTest}
                    />
                )}
            </Cover>
        </>
    );
};

export default NewTestimonial;
const PlayIcon = styled.div`
    position: absolute;
    width: 55px;
    z-index: 10;
    height: 55px;
    background: #4b92fe;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    padding: 18px;
    right: 10%;
    bottom: -8%;
    &:hover {
        box-shadow: 2px 0px 20px -10px rgba(0, 0, 0, 0.75);
    }
    img {
        width: 100%;
        transform: translateX(2px);
    }
    /* @media (max-width: 1280px) {
		bottom: 15%;
	}
	@media (max-width: 1080px) {
		width: 50px;
		height: 50px;
		bottom: 18%;
		padding: 18px;
	}
	@media (max-width: 980px) {
		bottom: 15%;
	}
	@media (max-width: 840px) {
		bottom: 18%;
	}
	@media (max-width: 640px) {
		bottom: 14%;
	}
	@media (max-width: 480px) {
		width: 40px;
		height: 40px;
		padding: 15px;
		bottom: 18%;
	} */
    /* @media (max-width: 420px) {
		width: 50px;
		height: 50px;
		padding: 18px;
		bottom: 12%;
	} */
`;
const Cover = styled.div`
    padding-bottom: 90px;
    padding-top: 60px;
    @media (max-width: 480px) {
        padding-top: 10px;
        padding-bottom: 50px;
    }
`;
const TopSection = styled.div`
    margin-top: 50px;
    position: relative;
`;
const Header = styled.h4`
    font-size: 40px;
    font-family: "gordita_medium";
    span {
        position: relative;
    }
    b {
        color: #0fa76f;
    }
    @media (max-width: 980px) {
            font-size: 40px;
        }
    @media (max-width: 640px) {
            font-size: 30px;
        }
     @media (max-width: 480px) {
            font-size: 25px;
        }
`;
const Wraper = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const BottomSect = styled.div`
    margin-top: 50px;
    position: relative;
`;
const Testmonials = styled.div`
    width: 100%;
`;
const Widgets = styled.div`
    cursor: pointer;
    /* margin-right: 20px; */
    width: 92% !important;
    background: #f0f8ee;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    padding: 6px;
    @media (max-width: 640px) {
        width: 100% !important;
    }
`;
const Next = styled.div`
    width: 20px;
    height: 20px;
    background: red;
`;
const ImageContainer = styled.div`
    width: 100%;
    position: relative;
    // padding:5px;
    // background-color:#f0f8ee;
    img {
        width: 100%;
        display: block;
        border-radius: 5px;
    }
`;
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #00000026;
    height: 100%;
`;
const UserDtail = styled.div`
    padding: 20px;
    background: #f0f8ee;
`;
const Name = styled.h5`
    color: #003c3c;
    font-size: 17px;
    display: inline;
    font-family: gordita_medium;
    @media (max-width: 1280px) {
        font-size: 16px;
    }
    @media (max-width: 980px) {
        font-size: 16px;
    }
`;
const Designation = styled.h5`
    color: #4b92fe;
    font-size: 14px;
    font-family: gordita_regular;
    @media (max-width: 1280px) {
        font-size: 13px;
    }
    @media (max-width: 980px) {
        font-size: 13px;
    }
`;
const RectDesign = styled.div`
    position: absolute;
    top: 1%;
    left: 1%;
    width: 140px;
    img {
        width: 100%;
        display: block;
    }
    @media (max-width: 1280px) {
        left: 1%;
    }
    @media (max-width: 640px) {
        left: 1%;
    }
    @media (max-width: 420px) {
        left: 3%;
    }
`;
const ButtonSection = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: center;
    @media (max-width: 480px) {
        margin-top: 20px;
    }
`;
const Button = styled(Link)`
    padding: 15px 35px;
    border-radius: 5px;
    background: #0fa76f;
    /* margin-top: 55px; */
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    @media all and (max-width: 360px) {
        font-size: 14px;
        padding: 15px 30px;
    }
`;
const ArrowNext = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #abd47433;
    position: absolute;
    top: -14%;
    right: 1%;
    z-index: 1;
    @media (max-width: 768px) {
        top: -22%;
    }
    @media (max-width: 420px) {
        top: -10%;
        right:4% ;
    }
    @media (max-width: 360px) {
        top: -9%;
        right:1% ;

    }
`;
const ArrowPrev = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #abd47433;
    position: absolute;
    top: -14%;
    left: 92%;
    z-index: 1;

    /* :hover {
        color: #0fa76f;
    } */
    @media (max-width: 1280px) {
        left: 89%;
    }
    @media (max-width: 980px) {
        left: 86%;
    }
    @media (max-width: 840px) {
        left: 85%;
    }
    @media (max-width: 768px) {
        left: 85%;
        top: -22%;
    }
    @media (max-width: 720px) {
        left: 83%;
    }
    @media (max-width: 640px) {
        left: 81%;
    }
    @media (max-width: 540px) {
        left: 75%;
    }
    @media (max-width: 420px) {
        left: 70%;
        top: -10%;
    }

    @media (max-width: 360px) {
        left: 69%;
        top: -9%;
    }
`;
const UnderImage = styled.div`
    width: 120px;
    position: absolute;
    right: 0%;
    bottom: -10px;
    img {
        width: 100%;
        display: block;
    }
    @media (max-width: 640px) {
        width: 95px;
    }
`;
