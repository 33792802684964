import React, { useContext, useEffect, useRef, useState } from "react";
import Jdenticon from "react-jdenticon";
import styled from "styled-components";
import { Context } from "../context/store";

export default function SideBar({ show, setShow }) {
    const [isEdit, setIsEdit] = useState(false);
    const [modalType, setModalType] = useState("");
    const {
        state: {
            user_data: { name: user_name, phone },
            // campus_data: { campus_name: campus, division, course },
            // user_validate: {
            //     selected_country: { phone_code: code },
            // },
        },
    } = useContext(Context);
    const onLogout = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    const username = JSON.parse(localStorage.getItem("user_validate"));

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShow(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const truncateString = (string, maxLength = 50) => {
        if (!string) return null;
        if (string.length <= maxLength) return string;
        return `${string.substring(0, maxLength)}...`;
    };

    return (
        <Container show={show} ref={wrapperRef}>
            <div>
                <Top>
                    <TopContainer>
                        <ImageContainer>
                            <Jdenticon size="70" value={user_name} />
                        </ImageContainer>
                        <Row className="top">
                            <Name className="name">
                                {username.username ? username.username : "-"}
                            </Name>
                        </Row>
                    </TopContainer>
                    <Div>
                        <TopRow>
                            <Img
                                className="back"
                                onClick={() => setShow(false)}
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/31-01-2021/back.svg"
                                alt="Icon"
                            />
                            <Name className="topname">Profile</Name>
                        </TopRow>
                    </Div>
                </Top>
            </div>
            <Bottom title="Logout">
                <Row className="bottom" onClick={onLogout}>
                    <Img
                        className="logout-icon"
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/31-01-2021/logout.svg"
                        alt="Icon"
                    />
                    <Name className="logout">Logout</Name>
                </Row>
            </Bottom>
        </Container>
    );
}

// middle styles
const Img = styled.img`
    width: 28px;
    display: block;
    margin-right: 65px;
    &.back {
        cursor: pointer;
        margin-right: 0px;
    }
    &.middle {
        margin-right: 9px;
        width: 24px;
    }
    &.logout-icon {
        margin-right: 8px;
        width: 21px;
    }
`;
const MiddleContainer = styled.div`
    width: 100%;
    margin-top: 120px;
`;
const Card = styled.div`
    background: #f9f9fb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 9px 11px;
    margin-bottom: 10px;
    border: 1px solid #f5f3f3;
`;
const ImageContain = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Right = styled.div``;
const Heading = styled.p`
    font-size: 13px;
    color: grey;
`;
const Data = styled.h5`
    font-size: 14px;
    font-family: "gordita_medium";
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 22px 22px;
    background: #fff;
    /* box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
        0 12px 90px 0 rgb(103 151 255 / 11%); */
    box-shadow: -2px 11px 15px #00000024;
    border-radius: 8px;
    width: 375px;
    position: fixed;
    top: 0;
    right: ${(props) => (props.show ? "0" : "-375px")};
    bottom: 0px;
    z-index: 500;
    transition: 0.6s;
    @media only screen and (max-width: 480px) {
        padding: 20px 10px;
    }
    @media only screen and (max-width: 420px) {
        width: 300px;
        padding: 20px 10px;
    }
    @media only screen and (max-width: 360px) {
        width: 300px;
    }
    @media only screen and (max-width: 340px) {
        width: 280px;
    }
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 17px 13px;
    border-radius: 10px;
    width: 100%;
    background-image: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/28-01-2022/lines-bg.svg");
    background-repeat: no-repeat;
    background-color: #f7ffe9;
    background-size: cover;
`;
const TopContainer = styled.div`
    position: absolute;
    bottom: -96px;
    left: 0;
    right: 0;
`;
const Bottom = styled.div`
    width: 100%;
    justify-content: center;
`;
const ImageContainer = styled.div`
    background: #fff;
    margin: 0 auto;
    border: 6px solid #fff;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        display: flex;
    }
`;
const Div = styled.div`
    width: 100%;
    margin-bottom: 75px;
`;
const Name = styled.h3`
    font-family: "gordita_medium";
    font-size: 15px;
    text-transform: capitalize;
    /* margin-left: 9px; */
    &.name {
        margin-top: 8px;
        font-size: 18px;
        margin-bottom: 2px;
        text-align: center;
    }
    &.phone {
        font-size: 13px;
        color: #56c082;
    }
    &.topname {
        color: #9b9999;
        margin: auto 99px;
        @media only screen and (max-width: 480px) {
            margin: auto 112px;
        }
        @media only screen and (max-width: 420px) {
            margin: auto 66px;
        }
    }
    &.logout {
        color: #e72b2b;
        font-size: 14px;
    }
`;
const TopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 11px;
    &.top {
        flex-direction: column;
    }
    &.bottom {
        justify-content: flex-start;
        width: 80px;
        cursor: pointer;
    }
`;
const Icon = styled.img`
    width: 12px;
    display: block;
`;
const RowText = styled.span`
    font-family: "gordita_medium";
    font-size: 14px;
`;
const EditContainer = styled.span`
    width: 15px;
    margin-left: auto;
    cursor: pointer;
    & img {
        width: 100%;
        display: block;
    }
`;
