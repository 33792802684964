import { click } from "@testing-library/user-event/dist/click";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../context/store";
import Header from "../includes/Header";

function StartupSchoolLessonBar({
    topic_pk,
    selectedLesson,
    lessonData,
    setLessonData,
    setAllData,
    allData,
    filteredData,
    lessonSlug,
}) {
    const [active, setActive] = useState("active");
    const { state } = useContext(Context);
    const [dropdown, setDropdown] = useState("");
    const handleTab = () => {
        setActive("active");
    };
    useEffect(() => {
        setDropdown(filteredData.length > 0 && filteredData[0].id);
    }, [allData, filteredData]);

    return (
        <>
            <MainContainer>
                {lessonData?.map((lesson) => (
                    <LessonContainer>
                        <Lessons key={lesson.id}>
                            <LessonBar
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTab();
                                    setDropdown(lesson.id);
                                }}
                            >
                                <LessonNum className="g-regular">
                                    {lesson.name}
                                </LessonNum>
                                <ArrowContainer active={dropdown === lesson.id}>
                                    <Arrow
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/14-01-2022/down-arrow.svg"
                                        }
                                        alt="Arrow"
                                    />
                                </ArrowContainer>
                            </LessonBar>
                            {dropdown === lesson.id ? (
                                <AdditionalContainer
                                    className={
                                        active === "active" ? "active" : ""
                                    }
                                >
                                    {lesson.topics.map((topic) =>
                                        topic.status === "locked" ? (
                                            <LessonItemsLocked
                                                key={topic.id}
                                                className={
                                                    topic.id === topic_pk
                                                        ? "playing"
                                                        : ""
                                                }
                                                status="locked"
                                            >
                                                <ItemLeft>
                                                    <PlayIcon
                                                        src={
                                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/14-01-2022/play-lock.svg"
                                                        }
                                                        alt="lock"
                                                        status="locked"
                                                        className="locks"
                                                    />
                                                    <LessonTitle
                                                        className={
                                                            topic.id ===
                                                            topic_pk
                                                                ? "playing"
                                                                : "null"
                                                        }
                                                    >
                                                        {topic.description}
                                                    </LessonTitle>
                                                </ItemLeft>
                                                <ItemRight>
                                                    <Duration
                                                        className={
                                                            topic.id ===
                                                            topic_pk
                                                                ? "playing"
                                                                : null
                                                        }
                                                    >
                                                        {topic.duration}
                                                    </Duration>
                                                </ItemRight>
                                            </LessonItemsLocked>
                                        ) : topic.status === "completed" ? (
                                            <LessonItems
                                                key={topic.id}
                                                to={`/${lessonSlug}/${topic.id}`}
                                                className={
                                                    topic.id === topic_pk
                                                        ? "playing"
                                                        : ""
                                                }
                                            >
                                                <ItemLeft>
                                                    {topic.id === topic_pk ? (
                                                        <PlayIcon
                                                            src={
                                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/prime-programs/play-circle-white.svg"
                                                            }
                                                            alt="Play"
                                                        />
                                                    ) : (
                                                        <PlayIcon
                                                            src={
                                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/green-tick.svg"
                                                            }
                                                            alt="Play"
                                                        />
                                                    )}
                                                    <LessonTitle
                                                        className={
                                                            topic.id ===
                                                            topic_pk
                                                                ? "playing"
                                                                : "null"
                                                        }
                                                        preview={
                                                            topic.is_preview
                                                        }
                                                    >
                                                        {topic.description}
                                                    </LessonTitle>
                                                </ItemLeft>
                                                <ItemRight>
                                                    <Duration
                                                        className={
                                                            topic.id ===
                                                            topic_pk
                                                                ? "playing"
                                                                : null
                                                        }
                                                    >
                                                        {topic.duration}
                                                    </Duration>
                                                </ItemRight>
                                            </LessonItems>
                                        ) : topic.status === "pending" ? (
                                            <LessonItems
                                                key={topic.id}
                                                to={`/${lessonSlug}/${topic.id}`}
                                                className={
                                                    topic.id === topic_pk
                                                        ? "playing"
                                                        : ""
                                                }
                                            >
                                                <ItemLeft>
                                                    {topic.id === topic_pk ? (
                                                        <PlayIcon
                                                            src={
                                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/prime-programs/play-circle-white.svg"
                                                            }
                                                            alt="Play"
                                                        />
                                                    ) : (
                                                        <PlayIcon
                                                            src={
                                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/14-01-2022/play-black.svg"
                                                            }
                                                            alt="Play"
                                                            className="locks"
                                                        />
                                                    )}
                                                    <LessonTitle
                                                        className={
                                                            topic.id ===
                                                            topic_pk
                                                                ? "playing"
                                                                : "null"
                                                        }
                                                    >
                                                        {topic.description}
                                                    </LessonTitle>
                                                </ItemLeft>
                                                <ItemRight>
                                                    <Duration
                                                        className={
                                                            topic.id ===
                                                            topic_pk
                                                                ? "playing"
                                                                : null
                                                        }
                                                    >
                                                        {topic.duration}
                                                    </Duration>
                                                </ItemRight>
                                            </LessonItems>
                                        ) : (
                                            ""
                                        )
                                    )}
                                </AdditionalContainer>
                            ) : (
                                <></>
                            )}
                        </Lessons>
                    </LessonContainer>
                ))}
            </MainContainer>
        </>
    );
}

export default StartupSchoolLessonBar;

const MainContainer = styled.ul``;
const LessonContainer = styled.li`
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Lessons = styled.div``;
const LessonBar = styled.div`
    cursor: pointer;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 19px;
    margin: 0px 0px 10px 0px;
    border-radius: 9px;
    @media (max-width: 480px) {
        padding: 13px 16px;
    }
`;
const LessonNum = styled.span`
    font-size: 15px;
    margin-right: 5px;
    @media (max-width: 480px) {
        font-size: 14px;
    }
`;
const Arrow = styled.img`
    display: block;
    width: 100%;
`;
const ArrowContainer = styled.div`
    transform: rotate(${(props) => (!props.active ? "-90deg" : "0px")});
    transition: 0.5s;
    width: 15px;
`;
const AdditionalContainer = styled.div`
    height: 0px;
    overflow: hidden;
    transition: all 3s ease-in-out;
    &.active {
        height: max-content;
    }
`;
const LessonItemsLocked = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 31px;
    margin: 7px 0;
    cursor: ${(props) =>
        props.status === "locked" ? "not-allowed" : "pointer"};
    border-radius: 9px;
    background-color: #fff;
    color: #5f7ae9;
    &:first-child {
        margin-top: 0;
    }
    &.playing {
        background: linear-gradient(
            272deg,
            rgba(34, 193, 195, 1) 0%,
            rgba(99, 187, 76, 1) 0%,
            rgba(24, 152, 175, 1) 100%
        );
        color: #fff;
        transition: all 0.4s ease;
    }
    @media (max-width: 480px) {
        padding: 7px 12px;
    }
`;
const LessonItems = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 31px;
    /* margin: 7px 0; */
    cursor: ${(props) =>
        props.status === "locked" ? "not-allowed" : "pointer"};
    border-radius: 9px;
    background-color: #fff;
    color: #5f7ae9;
    &:first-child {
        margin-top: 0;
    }
    &.playing {
        background: linear-gradient(
            272deg,
            rgba(34, 193, 195, 1) 0%,
            rgba(99, 187, 76, 1) 0%,
            rgba(24, 152, 175, 1) 100%
        );
        color: #fff;
        transition: all 0.4s ease;
    }
    @media (max-width: 480px) {
        padding: 7px 12px;
    }
`;
const ItemLeft = styled.div`
    display: flex;
    align-items: center;
`;
const PlayIcon = styled.img`
    display: block;
    width: 10px;
    &.locks {
        width: 20px;
        min-width: 21px;
    }
    min-width: ${(props) => (props.status === "locked" ? "15px" : "20px")};
    @media (max-width: 480px) {
        width: 15px;
    }
`;
const LessonTitle = styled.h3`
    font-size: 15px;
    margin: 0 15px;
    font-family: gordita_regular;
    transform: translateY(1px);

    color: ${(props) => (props.preview ? "#5F7AE9" : "#000")};
    @media (max-width: 480px) {
        font-size: 13px;
    }
    @media (max-width: 640px) {
        margin-right: 10px;
        word-break: break-word;
    }
    &.playing {
        color: #fff;
    }
`;
const ItemRight = styled.div`
    display: flex;
    align-items: center;
`;

const Duration = styled.span`
    font-size: 12px;
    color: #000;
    @media (max-width: 480px) {
        font-size: 12px;
    }
    &.playing {
        color: #fff;
    }
`;
