import React, { memo } from "react";
import styled from "styled-components";
// import TechImage from "../../../assets/images/technology-landing/tech-school-image.svg";
import BackImage from "../../../assets/images/technology-landing/school-back.svg";
import Tickred from "../../../assets/images/technology-landing/tick-red.svg";
import TickCircle from "../../../assets/images/technology-landing/tick-circle.svg";
import TickGreen from "../../../assets/images/technology-landing/tick-green.svg";
import UnderLine from "../../../assets/images/technology-landing/underline.png";
import AOS from "aos";
import "aos/dist/aos.css";

const WhatIsStartupSchool = () => {
    AOS.init();
    return (
        <Cover>
            <Wrapper className="wrappertg">
                <Container>
                    <LeftSect data-aos="fade-right">
                        <TopImage>
                            <img
                                src={require("../../../assets/images/technology-landing/StartUpSchoolBanner.png")}
                                alt="techies-park"
                            />
                        </TopImage>
                        {/* <BackImg>
                            <img src={BackImage} alt="techies-park" />
                        </BackImg> */}
                    </LeftSect>
                    <RightSect data-aos="fade-left">
                        <Heding>
                            What is <br />
                            Talrop Startup
                            <span>
                                {" "}
                                School?
                                <UnderImage>
                                    <img src={UnderLine} alt="image" />
                                </UnderImage>
                            </span>
                        </Heding>
                        <SectionBottom>
                            <SectLine className="one">
                                <TickContain>
                                    <img src={Tickred} alt="image" />
                                </TickContain>
                                <ContentData>One year course</ContentData>
                            </SectLine>
                            <SectLine className="two">
                                <TickContain className="blue">
                                    <img src={TickGreen} alt="image" />
                                </TickContain>
                                <ContentData>
                                    Skill enhancement tasks
                                </ContentData>
                            </SectLine>
                            <SectLine className="three">
                                <TickContain className="green">
                                    <img src={TickCircle} alt="image" />
                                </TickContain>
                                <ContentData>
                                Hands-on experience with Talrop's startups
                                </ContentData>
                            </SectLine>
                        </SectionBottom>
                    </RightSect>
                </Container>
            </Wrapper>
        </Cover>
    );
};

export default WhatIsStartupSchool;
const Cover = styled.div`
    //   height: 100vh;
    padding: 90px 0;
    @media (max-width: 768px) {
        padding: 100px 0px;
    }
    @media (max-width: 480px) {
        padding: 50px 0px;
    }
`;
const UnderImage = styled.div`
    width: 90%;
    position: absolute;
    right: -25%;
    bottom: -20px;
    img {
        width: 100%;
        display: block;
    }
    @media (max-width: 480px) {
        width: 88%;
        right: -16px;
        bottom: -18px;
    }
`;

const Wrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const LeftSect = styled.div`
    width: 45%;
    border-radius: 10px;
    @media (max-width: 1280px) {
        width: 40%;
    }
    @media (max-width: 980px) {
        width: 75%;
        margin: 0 auto 20px;
    }
`;
const TopImage = styled.div`
    width: 100%;
    transform: translate(-7px, 30px);
    border-radius: 10px;
    overflow: hidden;
    img {
        width: 100%;
        display: block;
    }
    @media (max-width: 640px) {
        transform: translate(0px, 15px);
    }
`;
const RightSect = styled.div`
    width: 45%;
    padding: 30px 0px;
    @media (max-width: 1280px) {
        width: 50%;
    }
    @media (max-width: 980px) {
        width: 100%;
        padding-top: 0px;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 980px) {
        flex-wrap: wrap-reverse;
    }
`;
const Heding = styled.h4`
    font-size: 45px;
    font-family: gordita_medium;
    margin-bottom: 40px;
    span {
        position: relative;
    }
    @media (max-width: 980px) {
            font-size: 40px;
        }
        @media (max-width: 640px) {
            font-size: 30px;
        }
        @media (max-width: 480px) {
            font-size: 25px;
        }
`;
const SectionBottom = styled.div`
    margin-top: 20px;
`;
const SectLine = styled.div`
    padding: 5px;
    box-shadow: 26.978px 25.5963px 54.1179px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    width: 60%;
    background: #fff;
    margin-bottom: 25px;
    border-radius: 8px;

    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 1080px) {
        width: 100%;
    }
    &.two {
        width: 70%;
        @media (max-width: 1080px) {
            width: 100%;
        }
    }
    &.three {
        width: 90%;
        @media (max-width: 1080px) {
            width: 100%;
        }
    }
`;
const TickContain = styled.div`
    width: 40px;
    height: 40px;
    background: #fff4f3;
    // padding: 6px;
    border-radius: 8px;
    margin-right: 15px;
    &.blue {
        background: #e9f7f2;
    }
    &.green {
        background: #edf9ff;
    }
    img {
        width: 100%;
    }
    @media (max-width: 980px) {
        width: 35px;
        height: 35px;
    }
`;
const ContentData = styled.h4`
    font-size: 16px;
    font-family: gordita_medium;
    @media (max-width: 980px) {
        font-size: 14px;
    }
    @media (max-width: 320px) {
        font-size: 12px;
    }
`;
