import React, { useContext, useState } from "react";
import { Outlet, Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Jdenticon from "react-jdenticon";
import { Context } from "../context/store";
import SideBar from "./SideBar";

function Header() {
    const {
        state: { user_data, isHeaderHidden, user_validate },
    } = useContext(Context);

    const [show, setShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <>
            <SideBar show={show} setShow={setShow} />
            {/* <section className="wrapper"> */}
            <MainContainer className={isHeaderHidden ? "hide" : ""}>
                <SubContainer>
                    <LeftContainer>
                        <Logo
                            to={`${user_data.is_verified ? "/dashboard" : "/"}`}
                        >
                            <LogoImage
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-09-2022/Talrop_logo.svg"
                                alt="Logo"
                            />
                        </Logo>
                    </LeftContainer>
                    {user_data.is_verified ? (
                        <RightContainer onClick={() => setShow(true)}>
                            <Right>
                                <ProfilePicContainer>
                                    <Jdenticon
                                        size="36"
                                        value={user_data.name}
                                    />
                                </ProfilePicContainer>
                                <ProfileName>
                                    <h3>
                                        {user_validate.username
                                            ? user_validate.username
                                            : "User"}
                                    </h3>
                                </ProfileName>
                                <DownArrow
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/polygon.svg"
                                    alt="Icon"
                                ></DownArrow>
                            </Right>
                        </RightContainer>
                    ) : (
                        <RightContainer>
                            <SignInButton
                                onClick={() =>
                                    setSearchParams({ action: "login" })
                                }
                            >
                                Login
                            </SignInButton>
                        </RightContainer>
                    )}
                </SubContainer>
            </MainContainer>
            {/* </section> */}
            <Outlet />
        </>
    );
}

export default Header;
const SignInButton = styled.div`
    cursor: pointer;
    display: block;
    color: #fff;
    font-size: 16px;
    padding: 9px 30px;
    font-family: "gordita_medium";
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    &:hover {
        background-position: right center;
    }
    @media (max-width: 640px) {
        padding: 12px 25px;
    }
    @media (max-width: 480px) {
        padding: 10px 22px;
        font-size: 14px;
    }
`;

const MainContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background: #f9f9f9;
    height: 75px;
    z-index: 100;
    padding: 10px 0;
    &.hide {
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease;
    }
    @media (max-width: 480px) {
        padding: 10px 15px;
    }
`;
const SubContainer = styled.div`
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1400px) {
        width: 90%;
        margin: 0 auto;
    }
`;
const LeftContainer = styled.div`
    /* width: 50%; */
`;
const Logo = styled(Link)`
    display: block;
    width: 45px;
    @media (max-width: 640px) {
        width: 40px;
    }
    /* @media (max-width: 480px) {
        width: 60px;
    } */
`;
const LogoImage = styled.img`
    width: 100%;
    display: block;
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    cursor: pointer;
`;
const Right = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
const ProfilePicContainer = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const DownArrow = styled.img`
    display: block;
    width: 9px;
    margin-left: 9px;
`;
const ProfileName = styled.div`
    margin-left: 7px;
    color: #15232ed8;
    font-family: "gordita_medium";
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    h3 {
        font-size: 15px;
    }
`;
