import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";

function Gallery() {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <ArrowNext
                className={className}
                // style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            ></ArrowNext>
        );
    }
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <ArrowPrev
                className={className}
                // style={{ ...style, display: "block", background: "green" }}
                onClick={onClick}
            ></ArrowPrev>
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true,
                },
            },
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    infinite: true,
                    // dots: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                },
            },
        ],
    };
    const [data, setData] = useState([
        {
            id: 1,
            image: require("../../../assets/images/technology-landing/Image1.png"),
            description: "Christ College students visited Techies Park",
        },
        {
            id: 2,
            image: require("../../../assets/images/technology-landing/Image1.png"),
            description: "Christ College students visited Techies Park",
        },
        {
            id: 3,
            image: require("../../../assets/images/technology-landing/Image1.png"),
            description: "Christ College students visited Techies Park",
        },
        {
            id: 4,
            image: require("../../../assets/images/technology-landing/Image1.png"),
            description: "Christ College students visited Techies Park",
        },
        {
            id: 5,
            image: require("../../../assets/images/technology-landing/Image1.png"),
            description: "Christ College students visited Techies Park",
        },
    ]);
    return (
        <>
            <MainContainer>
                <Wrapper>
                    <TopContainer>
                        <Heading>
                            We Encourage Students To Engage In Different Type Of
                            Skill Enhance Activities
                        </Heading>
                    </TopContainer>
                    <Slider {...settings}>
                        {data.map((item) => (
                            <BottomCOntainer>
                                <SingleCard>
                                    <ImageDiv>
                                        <img src={item.image} alt="Image" />
                                    </ImageDiv>
                                    <Description>
                                        {item.description}
                                    </Description>
                                </SingleCard>
                            </BottomCOntainer>
                        ))}
                    </Slider>
                </Wrapper>
            </MainContainer>
        </>
    );
}
export default Gallery;
const MainContainer = styled.div`
    background-color: #212121;
    padding: 100px 0;
    @media (max-width: 480px) {
        padding: 20px 0;
    }
`;
const Wrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const TopContainer = styled.div`
    margin-bottom: 40px;
`;
const Heading = styled.p`
    /* font-family: gordita_medium; */
    font-size: 30px;
    color: #34b461;
    width: 55%;
    text-align: center;
    margin: 40px auto;
    /* font-family: "gordita-medium"; */
    @media (max-width: 1280px) {
        width: 65%;
    }
    @media (max-width: 1080px) {
        width: 65%;
        font-size: 24px;
        margin: 20px auto;
    }
    @media (max-width: 980px) {
        width: 75%;
        font-size: 20px;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        width: 100%;
        font-size: 20px;
    }
    @media (max-width: 360px) {
        width: 100%;
        font-size: 16px;
    }
`;
const BottomCOntainer = styled.ul``;
const SingleCard = styled.li`
    /* width: 33%; */
    /* height: 400px; */
    margin-top: 70px;
    @media (max-width: 480px) {
        margin-top: 40px;
    }
`;
const ImageDiv = styled.div`
    /* width: 50%; */
    margin-bottom: 10px;
    img {
        width: 100%;
        display: block;
        border-radius: 6px;
    }
`;
const Description = styled.p`
    color: #fff;
    font-size: 18px;
    width: 70%;
    @media (max-width: 480px) {
        width: 100%;
        font-size: 14px;
    }
`;
const ArrowNext = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #abd47433;
    position: absolute;
    top: 0%;
    right: 1%;
    z-index: 1;
    @media (max-width: 840px) {
        left: 93%;
        top: 5%;
    }
    @media (max-width: 768px) {
        top: 0%;
    }
    @media (max-width: 480px) {
        top: 0%;
        left: 87%;
    }
    @media (max-width: 320px) {
        top: 1%;
    }
`;
const ArrowPrev = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #abd47433;
    position: absolute;
    top: 0%;
    left: 92%;
    z-index: 1;
    @media (max-width: 1280px) {
        left: 89%;
    }
    @media (max-width: 980px) {
        left: 86%;
    }
    @media (max-width: 840px) {
        left: 85%;
        top: 5%;
    }
    @media (max-width: 768px) {
        left: 85%;
        top: 0%;
    }
    @media (max-width: 720px) {
        left: 83%;
    }
    @media (max-width: 640px) {
        left: 80%;
    }
    /* @media (max-width: 540px) {
        left: 75%;
    } */
    @media (max-width: 480px) {
        left: 70%;
        top: 0%;
    }

    @media (max-width: 320px) {
        left: 69%;
        top: 1%;
    }
`;
