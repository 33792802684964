import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// import { manageConfig } from "../../../axiosConfig";
// import ButtonLoader from "../loader/ButtonLoader";
import CountrySelector from "./CountrySelector";
// import NewOtpModal from "./NewOtpModal";
import { useSearchParams } from "react-router-dom";
// import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { communityConfig } from "../../helpers/axiosConfig";
import FormSuccessModal from "./FormSuccesModal";

const JoinNowModal = ({
    formModal,
    setFormModal,
    number,
    selected,
    setSelected,
    setModal,
    setNumber,
    isModal,
    name,
    setName,
    setCountry,
    country,
}) => {
    const [code, setCode] = useState(false);
    const [email, setEmail] = useState("");
    const [college, setCollege] = useState("");
    const [designation, setDesignation] = useState("");
    //   const [collages,setCollages]=useState('')
    const [collageError, setCollageError] = useState(false);
    const [designationError, setDesignationError] = useState(false);
    const [active, setActive] = useState("");
    const [activeDesignation, setActiveDesignation] = useState("");
    const [isDropdown, setDropdown] = useState(false);
    const [dropdownDesignations, setDropdownDesignations] = useState(false);
    const [education, setEducation] = useState("");
    const [institution, setInstitution] = useState("");
    const [isError, setError] = useState(false);
    const [year, setYear] = useState("");
    const [gender, setGender] = useState("Male");
    const [response, setResponse] = useState("");
    const wrapperRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("This field is required");
    const [isEmail, setEmailErr] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [phoneErrorMessage, setPhoneErrorMessage] = useState(
        "This field is required"
    );
    const [errormessage, setErrorMessage] = useState("");
    const [Error, setIsError] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
	const [isMailError, setMailError] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const [successModal, setSuccessModal] = useState(false);
    const refer = searchParams.get("r");
    const recaptchaRef = useRef(null);

    const onSelectHandler = (selected) => {
        setSelectedCountry(selected);
    };

    //validating phone-number
    const onNumberChange = (e) => {
        const re = /^[A-Za-z0-9]+$/;
        setValidNumber(false);
        if (e.target.value === "" || re.test(e.target.value)) {
            setNumber(e.target.value.replace("e" && ".", ""));
        }
    };

	const validateEmail = (email) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  );
	  };

    const handleNumberError = () => {
        if (!number) {
            setError(true);
            setPhoneErrorMessage("This field is required");
        } else if (number.length < 10 || number.length > 10) {
            setError(true);
            setPhoneErrorMessage("Invalid phone number");
        } else {
            setError(false);
            setPhoneErrorMessage("");
        }
    };

    const validate = (e) => {
        window.addEventListener(
            "keydown",
            function (e) {
                if (["ArrowUp", "ArrowDown"].indexOf(e.code) > -1) {
                    e.preventDefault();
                }
            },
            false
        );
    };

    const designations = [
        {
            id: 1,
            title: "student",
        },
        {
            id: 2,
            title: "teacher",
        },
    ];

    const genders = [
        {
            id: 1,
            _gender: "male",
        },
        {
            id: 2,
            _gender: "female",
        },
        {
            id: 3,
            _gender: "other",
        },
    ];

    useEffect(() => {
        handleNumberError();
        document.body.style.overflow = "hidden";
        setGender("")
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    const resetvalues = () => {
        setName("");
        setEmail("");
        setCollege("");
        setDesignation("");
        setNumber("");
    };

    const onFormSubmit = () => {
        // handleEmailError();
        handleNumberError();
        // e.preventDefault();
        // setLoading(true);
        // setError(true);

		if (!validateEmail(email) && email !== "") {
			setMailError(true);
			setEmailErrorMessage("Invalid mail");
		  } else {
			setMailError(false);
			setEmailErrorMessage("");
		  }
        if (
			validateEmail(email) ||
            (email === "" &&
			name !== "" &&
            gender !== "" &&
            selectedCountry.web_code !== "" &&
            college !== "" &&
            number !== "" &&
            designation !== "")
        ) {
            const formData = new FormData();

            formData.append("name", name);
            formData.append("phone", number);
            formData.append("country", selectedCountry.web_code);
            formData.append("gender", gender);
            formData.append("campus", college);
            formData.append("email", email);
            formData.append("designation", designation);

            communityConfig
                .post("campuses/startup-school/registration/", formData)
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        // setLoading(false);
                        setSuccessModal(true);
                        resetvalues();
                        setNumber("")
                        // setFormModal(false);/
                    } else if (StatusCode === 6001) {
                        setError(true);
                        setIsError(true);
                        setErrorMessage(data.message);
                        // setSuccessModal(false);
                        if (
                            response.data.data.message ===
                            "Entered number is not a valid phone number"
                        ) {
                            //   setValidNumber(true);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setLoading(false);
            setError(true);
        }
    };
    return (
        <>
            <Cover formModal={formModal} >
                <Container formModal={formModal} >
                    <CloseContainer onClick={() => setFormModal(false)}>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                            alt="image"
                        />
                    </CloseContainer>
                    <Top>
                        <Left>
                            <h4>Join Startup School</h4>
                        </Left>
                        <p>
                            Startup School enables participants to see the world
                            as entrepreneurs do, while providing the knowledge
                            and skills they need.
                        </p>
                    </Top>

                    <Bottom>
                        <form
                            onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <InputContainer>
                                <Title>Full name*</Title>
                                <InputFields>
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        value={name}
                                    />
                                </InputFields>
                                {isError === true && name === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Phone Number*</Title>
                                <InputFields>
                                    <LeftFlag onClick={() => setCode(!code)}>
                                        <ImageCont>
                                            <img
                                                src={selected.flag}
                                                alt="flag"
                                            />
                                        </ImageCont>
                                        <Code>{selected.phone_code}</Code>
                                    </LeftFlag>
                                    <input
                                        type="number"
                                        placeholder="Enter your number"
                                        value={number}
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                            [
                                                "e",
                                                "E",
                                                ".",
                                                "+",
                                                "-",
                                                "/",
                                                "&",
                                                "^",
                                                "(",
                                                ")",
                                                "_",
                                                "%",
                                                "#",
                                                "@",
                                                "!",
                                                "~",
                                            ].includes(e.key) &&
                                                e.preventDefault();
                                        }}
                                        onKeyPress={(e) => validate(e)}
                                    />
                                </InputFields>

                                <ErrorMessage
                                    className={
                                        isError === true && validNumber === true
                                            ? "error-active"
                                            : ""
                                    }
                                ></ErrorMessage>
                                {isError === true && number === "" ? (
                                    <ErrorMessage>
                                        {" "}
                                        {phoneErrorMessage}
                                    </ErrorMessage>
                                ) : (
                                    <ErrorMessage
                                        className={
                                            isError === true
                                                ? "error-active"
                                                : ""
                                        }
                                    >
                                        {phoneErrorMessage}
                                    </ErrorMessage>
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Email</Title>
                                <InputFields>
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        value={email}
                                    />
                                </InputFields>
                                {isEmail ? (
                                    <ErrorMessage>{emailError}</ErrorMessage>
                                ) : isMailError? (
                                    <ErrorMessage>{emailErrorMessage}</ErrorMessage>
                                )
                                :""}
                            </InputContainer>
                            <InputContainer>
                                <Title>Gender*</Title>
                                <InputFields className="gendercontainer">
                                    {genders.map((__gender) => (
                                        <CoverCont
                                            key={__gender.id}
                                            onClick={(e) =>
                                                setGender(__gender._gender)
                                            }
                                        >
                                            <Radio
                                                active={
                                                    gender === __gender._gender
                                                }
                                            >
                                                <InnerRadio
                                                    active={
                                                        gender ===
                                                        __gender._gender
                                                    }
                                                ></InnerRadio>
                                            </Radio>
                                            <label for="male">
                                                {__gender._gender}
                                            </label>
                                        </CoverCont>
                                    ))}
                                </InputFields>
                                {isError === true && gender === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>College*</Title>
                                <InputFields>
                                    <input
                                        type="text"
                                        placeholder="Enter name of the college"
                                        onChange={(e) =>
                                            setCollege(e.target.value)
                                        }
                                        value={college}
                                    />
                                </InputFields>
                                {isError === true && college === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Designation*</Title>
                                <InputFields
                                    onClick={() =>
                                        setDropdownDesignations(
                                            !dropdownDesignations
                                        )
                                    }
                                >
                                    <DistrictContainer collage={designation}>
                                        {designation
                                            ? designation
                                            : "Select designation"}
                                    </DistrictContainer>
                                    <DownArrow active={activeDesignation}>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-06-2022/down-arrow.svg"
                                            alt="Down Arrow"
                                        />
                                    </DownArrow>
                                    <Dropdown
                                        isDropdown={dropdownDesignations}
                                        collage={designation}
                                        ref={wrapperRef}
                                    >
                                        {designations.map((designation) => (
                                            <div
                                                key={designation.id}
                                                onClick={() => {
                                                    setDesignation(
                                                        designation.title
                                                    );
                                                    setActiveDesignation("");
                                                    setDropdownDesignations(
                                                        !dropdownDesignations
                                                    );
                                                    setDesignationError("");
                                                }}
                                            >
                                                {designation.title}
                                            </div>
                                        ))}
                                    </Dropdown>
                                </InputFields>
                                {isError === true && designation === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                        </form>
                        <ButtonSect>
                            {response !== "" ? (
                                <Response>
                                    {response === "Validation Error"
                                        ? ""
                                        : response}
                                </Response>
                            ) : (
                                ""
                            )}
                            {loading ? (
                                ""
                            ) : (
                                // <Loader>
                                //     <ButtonLoader />
                                // </Loader>
                                <input
                                    type="submit"
                                    value="Continue"
                                    onClick={() => {
                                        onFormSubmit();
                                    }}
                                />
                            )}
                        </ButtonSect>
                    </Bottom>
                    <ErrorMessage
                        className={Error === true ? "errormessage" : ""}
                    >
                        {errormessage}
                    </ErrorMessage>
                </Container>
                <FormSuccessModal
                    successModal={successModal}
                    setSuccessModal={setSuccessModal}
                    setFormModal={setFormModal}
                />
                {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    //This ref can be used to call captcha related functions in case you need.
                    sitekey="6LdWOwMiAAAAAGY2aaaDt3dRgAMerfhPPY1RG6oc"
                    size="invisible"
                    badge="bottomleft"
                /> */}
            </Cover>
            <CountrySelector
                selected={selected}
                setSelected={setSelected}
                code={code}
                setCode={setCode}
                setCountry={setCountry}
                onSelectHandler={onSelectHandler}
                selectedCountry={selectedCountry}
            />
        </>
    );
};

export default JoinNowModal;
const Loader = styled.div`
    width: 48%;
    height: 45px;
    background: #0fa76f;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    font-size: 15px;
    display: flex;
    align-items: center;
`;
const Cover = styled.div`
    height: 100vh;
    width: 100%;
    display: ${({ formModal }) => (formModal ? "block" : "none")};
    background: #00000090;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CoverCont = styled.div`
    display: flex;
    align-items: center;
    label {
        font-size: 14px;
        margin-right: 15px;
        cursor: pointer;
        @media (max-width: 420px) {
            font-size: 12px;
            margin-right: 10px;
        }
    }
    input {
        margin-right: 5px;
        font-size: 15px;
        cursor: pointer;
    }
`;
const Container = styled.div`
    position: relative;
    background: #fff;
    padding: 20px 40px;
    width: 60%;
    border-radius: 10px;
    transform: ${({ formModal }) => (formModal ? "scale(1)" : "scale(0)")};
    // max-height: 600px;
    /* overflow-y: scroll; */
    //   padding-bottom:40px;
    @media (max-width: 1080px) {
        width: 60%;
    }
    @media (max-width: 640px) {
        max-height: 550px;
        overflow-y: scroll;
    }
    @media (max-width: 980px) {
        width: 75%;
    }
    @media (max-width: 768px) {
        width: 85%;
    }
    @media (max-width: 360px) {
        padding: 20px 20px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const Dropdown = styled.div`
    min-width: 280px;
    width: 100%;
    max-height: 180px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border: 1px solid #e0e0e0;
    display: ${({ isDropdown }) => (isDropdown ? "block" : "none")};
    transition: all 0.5s ease;
    position: absolute;
    box-shadow: 0px 5px 31px -7px rgba(119, 122, 119, 0.98);
    border-radius: 8px;
    background: #fff;
    top: 100%;
    z-index: 10;
    div {
        padding: 15px 30px;
        font-size: 13px;
        font-family: gordita_regular;
        cursor: pointer;
    }
`;
const Top = styled.div`
    /* display: flex;
    justify-content: space-between; */
    padding: 20px 0px;
    position: relative;
    &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #f0eeee;
        position: absolute;
        bottom: 0;
    }
    p {
        max-width: 70%;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #838383;
        @media all and (max-width: 1080px) {
            max-width: 100%;
        }
        @media all and (max-width: 640px) {
            font-size: 14px;
        }
    }
`;
const Code = styled.div`
    font-size: 15px;
    color: #000;
    @media (max-width: 640px) {
    }
`;
const DownArrow = styled.div`
    width: 22px;
    transform: ${({ active }) =>
        active === "dist" ? "rotate(180deg)" : "rotate(0deg)"};
    transition: all 0.5s ease;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;
const Left = styled.span`
    display: inline-block;
    margin-bottom: 12px;
    h4 {
        font-size: 22px;
        font-family: gordita_medium;
    }
`;
const CloseContainer = styled.div`
    background-color: #fff;
    position: absolute;
    top: -18px;
    right: -18px;
    cursor: pointer;
    padding: 12px;
    border: 3px solid #f3f3f3;
    border-radius: 50%;

    img {
        width: 14px;
    }

    @media all and (max-width: 640px) {
        top: 12px;
        right: 12px;
        border: none;
    }
`;
const Bottom = styled.div`
    margin-top: 10px;
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;
const InputContainer = styled.div`
    width: 48%;
    margin-top: 25px;
    position: relative;
    cursor: pointer;
    @media (max-width: 640px) {
        width: 100%;
    }
`;
const Title = styled.h4`
    font-size: 13px;
    color: #6b6b6b;
    margin-bottom: 5px;
`;
const InputFields = styled.div`
    width: 100%;
    height: 48px;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    text-transform: capitalize;
    &.gendercontainer {
        /* border: 1px solid #e7e7e7; */
        border: none;
        padding-left: 8px;
        // display:
    }
    //   label {
    //     color: #afafaf;
    //     margin-left: 10px;
    //   }
    input {
        width: 100%;
        height: 100%;
        padding: 11px;
        font-size: 15px;
        &::placeholder {
            font-size: 13px;
        }
        &.gender {
            width: 20px;
            height: 20px;
            @media (max-width: 420px) {
                width: 15px;
                height: 15px;
            }
            //   margin-left: 30px;
        }
    }
`;
const DistrictContainer = styled.div`
    cursor: pointer;
    font-size: 13px;
    font-family: gordita_regular;
    width: 100%;
    padding: 14px 11px;
    color: ${({ collage }) => (collage ? "#000" : "#9b9a9a")};
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 540px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 12px;
        padding: 10px 15px;
    }
`;
const ButtonSect = styled.div`
    width: 100%;
    margin-top: 45px;
    padding-bottom: 70px;
    position: relative;
    input {
        width: 48%;
        height: 45px;
        background: #0fa76f;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        float: right;
        font-size: 15px;
    }
`;
const LeftFlag = styled.div`
    display: flex;
    margin-left: 8px;
    // width: 20%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1280px) {
        width: 26%;
    }
    @media (max-width: 640px) {
        width: 15%;
    }
    @media (max-width: 480px) {
        width: 25%;
    }
`;
const ImageCont = styled.div`
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
    @media (max-width: 640px) {
        width: 20px;
        height: 20px;
    }
    img {
        width: 150%;
        display: block;
        object-fit: contain;
        transform: translateX(-8px);
        @media (max-width: 640px) {
            transform: translateX(-5px);
        }
    }
`;
const ErrorMessage = styled.p`
    color: red;
    position: absolute;
    right: 0;
    bottom: -25px;
    font-size: 12px;

    &.error-active {
        color: red;
        font-size: 12px;
        display: none;
        text-align: right;
        position: absolute;
        bottom: -22px;
        right: 0;
        display: block;
    }
    &.errormessage {
        color: red;
        position: absolute;
        left: 40px;
        bottom: 92px;
        font-size: 12px;
        @media (max-width: 840px) {
            bottom: -245px;
            left: 40px;
        }
        @media (max-width: 640px) {
            bottom: -245px;
            left: 40px;
        }
        @media (max-width: 480px) {
            bottom: -265px;
            left: 40px;
        }
        @media (max-width: 360px) {
            bottom: -265px;
            left: 40px;
        }
    }
`;
const Response = styled.p`
    color: red;
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 12px;
`;

const Radio = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid ${({ active }) => (active ? "#4CA473" : "#ADADAD")};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
`;
const InnerRadio = styled.span`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ active }) => (active ? "#4CA473" : "#fff")};
`;
