import React from "react";
import { useState } from "react";
import { Link as Direction } from "react-scroll";
import { ScrollLink } from "react-scroll";
import styled from "styled-components";
import BackImage from "../../../assets/images/technology-landing/background.png";
import FormSuccesModal from "../../tefun/modals/FormSuccesModal";

function ApplyNow() {
    return (
        <>
            {/* <Header /> */}
            <Cover id="applynow">
                <Wrapper className="wrappertg">
                    <BackCover>
                        <LeftSection>
                            <Heading>
                                <h4>
                                    Launch <Green>Startup School</Green> in Your
                                    Campus
                                </h4>
                            </Heading>
                            <SubHead>
                                Let your students change the world
                            </SubHead>
                            <FormSection>
                                <Link
                                    to="home"
                                    // href="home"
                                    activeClass="active"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={300}
                                >
                                    {" "}
                                    Apply Now
                                </Link>
                            </FormSection>
                        </LeftSection>
                        <RightSection>
                            <TopImage>
                                <img
                                    src={require("../../../assets/images/technology-landing/applynow.png")}
                                    alt="image"
                                />
                                {/* <Play>
                                    <img
                                        src={require("../../../assets/images/technology-landing/play-image.png")}
                                        alt="Play"
                                    />
                                </Play> */}
                            </TopImage>
                        </RightSection>
                    </BackCover>
                </Wrapper>
            </Cover>
        </>
    );
}

export default ApplyNow;
const Cover = styled.div`
    // min-height:100vh;
    padding: 100px 0;
    overflow: hidden;
    background-color: #f9faf8;
    position: relative;
    @media (max-width: 640px) {
        padding: 50px 0;
    }
`;
const Wrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const BackCover = styled.div`
    // height:90vh;
    border-radius: 20px;
    /* padding: 50px 60px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1080px) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 640px) {
        @media (max-width: 640px) {
            padding: 28px;
        }
    }
    @media (max-width: 640px) {
        padding: 28px 10px;
    }
`;
const LeftSection = styled.div`
    width: 43%;
    /* padding: 60px 0px; */
    text-align: center;
    @media (max-width: 1280px) {
        width: 47%;
    }
    @media (max-width: 1080px) {
        padding: 0 0 60px 0;
        width: 100%;
    }
`;

const Heading = styled.div`
    /* width: 90%; */
    text-align: center;
    h4 {
        font-size: 40px;
        font-family: gordita_medium;
        color: #212121;
        @media (max-width: 640px) {
            font-size: 30px;
        }
        @media (max-width: 480px) {
            font-size: 22px;
        }
    }
`;
const Green = styled.span`
    color: #0fa76f !important;
`;
const SubHead = styled.p`
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
`;
const FormSection = styled.button`
    background: #0fa674;
    font-family: "gordita_medium";
    min-width: 25%;
    border-radius: 3px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;

    @media (max-width: 480px) {
        min-width: 35%;
    }

    @media (max-width: 480px) {
        input {
            width: 38%;
        }
    }
`;
const Link = styled(Direction)`
    color: #fff;
`;
const FormCover = styled.div`
    width: 70%;
    padding: 5px;
    background: #fff;
    border: 1px solid #d2e4de;
    border-radius: 10px;
    display: flex;
    margin-top: 30px;
    position: relative;
    @media (max-width: 1080px) {
        width: 55%;
    }
    @media (max-width: 980px) {
        width: 80%;
    }
    @media (max-width: 768px) {
        width: 75%;
    }
    @media (max-width: 640px) {
        width: 90%;
    }
    @media (max-width: 420px) {
        width: 100%;
    }
    input {
        width: 55%;
        // background:red;
        margin-left: 5px;
        font-size: 14px;
        color: #000;
        &.button {
            background: #0fa674;
            font-family: "gordita_medium";
            width: 30%;
            border-radius: 3px;
            height: 40px;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            @media (max-width: 640px) {
                font-size: 12px;
                height: 35px;
            }
            @media (max-width: 360px) {
                font-size: 11px;
            }
        }
    }
`;
const LeftFlag = styled.div`
    display: flex;
    // width: 17%;
    // justify-content: space-between;
    align-items: center;
    // margin-right:5px;
    cursor: pointer;
    @media (max-width: 480px) {
        width: 21%;
    }
`;
const ImageCont = styled.div`
    width: 30px;
    height: 30px;
    // background: green;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
    @media (max-width: 640px) {
        width: 23px;
        height: 20px;
    }
    img {
        width: 100%;
        display: block;
        object-fit: contain;
        transform: translateX(-8px);
        @media (max-width: 640px) {
            transform: translateX(-5px);
        }
    }
`;
const Code = styled.div`
    font-size: 14px;
    color: #000;
    @media (max-width: 640px) {
        font-size: 13px;
    }
`;
const Container = styled.div`
    margin-top: 30px;
`;
const SectionLine = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;
const RectLeft = styled.div`
    width: 40px;
    height: 40px;
    background: #e9f7f2;
    // padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-right: 10px;
    img {
        width: 100%;
    }
`;
const Descript = styled.h5`
    width: calc(100% - 50px);
    font-size: 18px;
    color: #212121;
    font-family: gordita_medium;
    @media (max-width: 480px) {
        font-size: 13px;
    }
`;
const RightSection = styled.div`
    position: relative;
    width: 50%;
    border-radius: 10px;
    @media (max-width: 1280px) {
        width: 48%;
        margin: 0 auto;
    }
    @media (max-width: 1080px) {
        width: 50%;
        margin: 0 auto;
    }
    @media (max-width: 980px) {
        width: 50%;
        margin: 0 auto;
    }
    @media (max-width: 768px) {
        width: 85%;
        margin: 0 auto 20px;
    }
`;
const TopImage = styled.div`
    /* width: 80%; */
    /* z-index: 9;
    border-radius: 10px;
    overflow: hidden;
    transform: translate(30px, -30px);
    position: relative; */
    img {
        width: 100%;
        display: block;
    }
    @media (max-width: 640px) {
        width: 100%;
        /* transform: translate(15px, -15px); */
    }
`;
const DesignImage = styled.div`
    width: 80%;
    position: absolute;
    left: 10%;
    top: 12%;
    img {
        width: 100%;
    }
    @media (max-width: 1080px) {
        // position:inherit;
        width: 65%;
    }
`;
const TickContainer = styled.div`
    position: absolute;
    top: -30px;
    right: -60px;
    width: 50px;
    height: 50px;
    & img {
        width: 100%;
        display: block;
    }
    @media (max-width: 420px) {
        display: none;
    }
`;
const Errormsg = styled.h4`
    color: red;
    font-size: 13px;
    margin-top: 5px;
`;
const Play = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ff6446;
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 46%;
    left: 48%;
    transform: translate(-50%, -50%);

    & img {
        width: 100%;
        display: block;
    }
`;
const Bubble = styled.div`
    width: 150px;
    height: 50px;
    // border-radius:50%;
    // background: linear-gradient(180deg, #0FA76F 0%, #0B6846 100%);
    position: fixed;
    bottom: 10%;
    right: 10%;
    z-index: 11;
    // padding:15px;
`;
const CallBubble = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 10;
    transition: 0.4s ease;
    &.active {
        // transform: translateX(-120px);
        right: 80%;
        transition: 0.4s ease;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const WhatsBubble = styled.a`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    &.active {
        // transform:translateX(-60px);
        right: 40%;
        transition: 0.4s ease;
    }
    &.call-active {
        right: 80%;
        transition: 0.4s ease;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const CloseBubble = styled.div`
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    img {
        width: 100%;
        display: block;
    }
`;

// const Container = styled.section``
