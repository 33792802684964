import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import Spotlight from "./Spotlight";
import Topbar from "./Topbar";
import { Context } from "../../context/store";
import StartupSchoolSpotlight from "./StartupSchoolSpotlight";
import CallImage from "../../../assets/images/technology-landing/Call.svg";
import CallingImage from "../../../assets/images/technology-landing/calling.svg";
import WhatImage from "../../../assets/images/technology-landing/whatsapp.png";
import CloseImage from "../../../assets/images/technology-landing/closegreen.svg";
import WhatIsStartupSchool from "./WhatIsStartupSchool";
import Header from "../../includes/Header";
import Gallery from "./Gallery";
import ApplyNow from "./ApplyNow";
import NewTestimonial from "./NewTestimonial";
import TechnologyFooter from "./TechnologyFooter";

function LandingPage() {
    const { dispatch } = useContext(Context);
    const [isBubble, setBubble] = useState(false);

    useEffect(() => {
        dispatch({
            type: "UPDATE_HEADER_HIDDEN",
            value: true,
        });
        return () => {
            dispatch({
                type: "UPDATE_HEADER_HIDDEN",
                value: false,
            });
        };
    }, []);

    return (
        <Container>
            <Topbar />
            <StartupSchoolSpotlight />
            <WhatIsStartupSchool />
            <Gallery />
            <NewTestimonial />
            <ApplyNow />
            <TechnologyFooter />
            <Bubble>
                {isBubble ? null : (
                    <CallBubble
                        isBubble={isBubble}
                        onClick={() => {
                            setBubble(true);
                        }}
                        // className={isBubble && "active"}
                    >
                        <img src={CallImage} alt="image" />
                    </CallBubble>
                )}
                <WhatsBubble
                    isBubble={isBubble}
                    href=""
                    className={isBubble && "call-active"}
                >
                    <img src={CallingImage} alt="image" />
                </WhatsBubble>
                <WhatsBubble
                    isBubble={isBubble}
                    className={isBubble && "active"}
                    href=""
                    target="_blank"
                >
                    <img src={WhatImage} alt="image" />
                </WhatsBubble>
                <CloseBubble
                    isBubble={isBubble}
                    onClick={() => setBubble(false)}
                >
                    <img src={CloseImage} alt="image" />
                </CloseBubble>
            </Bubble>
        </Container>
    );
}

export default LandingPage;

const Container = styled.div`
    width: 100%;
    overflow-x: unset;
    /* overflow: hidden; */
    position: relative;
    /* @media (max-width: 1080px) {
        overflow-x: hidden;
    } */
`;
const Bubble = styled.div`
    width: 150px;
    height: 50px;
    // border-radius:50%;
    // background: linear-gradient(180deg, #0FA76F 0%, #0B6846 100%);
    position: fixed;
    bottom: 10%;
    right: 10%;
    z-index: 11;
    // padding:15px;
`;
const CallBubble = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 10;
    transition: 0.4s ease;
    &.active {
        // transform: translateX(-120px);
        right: 80%;
        transition: 0.4s ease;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const WhatsBubble = styled.a`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    &.active {
        // transform:translateX(-60px);
        right: 40%;
        transition: 0.4s ease;
    }
    &.call-active {
        right: 80%;
        transition: 0.4s ease;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const CloseBubble = styled.div`
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    padding: 15px;
    position: absolute;
    right: 0;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    img {
        width: 100%;
        display: block;
    }
`;

// const Container = styled.section``
