import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { Context } from "../../context/store";
import { Link as Direction } from "react-scroll";
import HeaderHamburgerModal from "../../tefun/modals/HeaderHamburgerModal";
// import { Link as Direction } from "react-range";

function Topbar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState("");
    const [isModal, setModal] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const {
        state: { user_data },
    } = useContext(Context);

    const handleNavbar = () => {
        if (window.scrollY >= 50) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", handleNavbar);

    var prevScrollpos = window.pageYOffset;
    const handleScroll = () => {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            if (document.getElementById("navbar")) {
                document.getElementById("navbar").style.top = "0";
                document.getElementById("navbar").style.transition = "all 0.8s";
            }
        } else if (prevScrollpos <= 30) {
            document.getElementById("navbar").style.top = "0";
        } else {
            document.getElementById("navbar").style.top = "-200px";
        }
        prevScrollpos = currentScrollPos;
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    });

    return (
        <>
            <HeaderHamburgerModal
                isMenu={isModal}
                setMenu={setModal}
                active={active}
                setActive={setActive}
            />
            <TopBar id="navbar" className={navbar && "active"}>
                <NavContainer className="wrapper">
                    <LeftSection>
                        <h1>
                            <Logo
                                to={`${
                                    user_data.is_verified ? "/dashboard/" : "/"
                                }`}
                            >
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-09-2022/Talrop_logo.svg"
                                    alt="Logo"
                                />
                            </Logo>
                        </h1>
                    </LeftSection>
                    <RightSection>
                        <Text
                            to="applynow"
                            // href="home"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={300}
                        >
                            Launch Videos
                        </Text>
                        <TextTwo
                            to="testimonials"
                            // href="home"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            duration={300}
                        >
                            Testimonials
                        </TextTwo>
                        {user_data.is_verified ? (
                            <LinkButton to="/tefun">
                                Continue Learning
                            </LinkButton>
                        ) : (
                            <SignInButton
                                onClick={() =>
                                    setSearchParams({ action: "login" })
                                }
                            >
                                Login
                            </SignInButton>
                        )}
                    </RightSection>
                    <Hamburger onClick={() => setModal(!isModal)}>
                        <LineSpan isModal={isModal}></LineSpan>
                        <LineSpan type="middle" isModal={isModal}></LineSpan>
                        <LineSpan type="last" isModal={isModal}></LineSpan>
                    </Hamburger>
                </NavContainer>
            </TopBar>
        </>
    );
}

export default Topbar;
const TopBar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    height: 90px;
    /* padding: 20px 0; */
    transition: 0.5s;
    background: #f0f8ee;
    &.active {
        box-shadow: 0 16px 24px rgb(0 0 0 / 3%);
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
    }
`;
const NavContainer = styled.div`
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LeftSection = styled.div`
    width: 50px;
    @media (max-width: 640px) {
        width: 40px;
    }
`;
const Logo = styled(Link)`
    width: 25px;
    cursor: pointer;
    & img {
        display: block;
        width: 100%;
    }
`;
const RightSection = styled.div`
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1280px) {
        width: 40%;
    }
    @media (max-width: 1080px) {
        width: 50%;
        font-size: 14px;
    }
    @media (max-width: 980px) {
        width: 50%;
    }
    @media (max-width: 840px) {
        width: 55%;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;
const Text = styled(Direction)`
    font-family: "gordita_medium";
    color: rgb(102, 102, 102);
    cursor: pointer;
    &:hover {
        color: #61b950;
    }
`;
const TextTwo = styled(Direction)`
    font-family: "gordita_medium";
    color: rgb(102, 102, 102);
    cursor: pointer;
    &:hover {
        color: #61b950;
    }
`;
const SignInButton = styled.div`
    cursor: pointer;
    display: block;
    color: #fff;
    font-size: 16px;
    padding: 10px 25px;
    font-family: "gordita_medium";
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    &:hover {
        background-position: right center;
        /* text-decoration: none; */
    }
    @media (max-width: 640px) {
        padding: 8px 17px;
        font-size: 14px;
    }
    @media (max-width: 480px) {
        padding: 10px 22px;
        font-size: 14px;
    }
`;
const LinkButton = styled(Link)`
    display: block;
    color: #fff;
    font-size: 16px;
    padding: 15px 30px;
    font-family: "gordita_medium";
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
        to right,
        #63bb4c 0%,
        #1898af 51%,
        #63bb4c 100%
    );
    &:hover {
        background-position: right center;
        /* text-decoration: none; */
    }
    @media (max-width: 640px) {
        padding: 12px 25px;
    }
    @media (max-width: 480px) {
        text-align: center;
        padding: 10px 19px;
        font-size: 14px;
    }
    @media (max-width: 360px) {
        padding: 10px 15px;
        width: 190px;
    }
`;
const HandBurger = styled.span`
    cursor: pointer;
    display: block;
    & img {
        display: block;
        width: 100%;
        @media (max-width: 640px) {
            width: 90%;
        }
        @media (max-width: 480px) {
            width: 70%;
        }
    }
`;
const Hamburger = styled.div`
    width: 50px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
    display: none;
    @media all and (max-width: 768px) {
        display: flex;
    }
`;
const LineSpan = styled.span`
    width: ${({ type, isModal }) =>
        !isModal ? (type === "middle" ? "35px" : "45px") : "40px"};
    height: 5px;
    background: ${({ type }) => (type === "last" ? "#96CA4C" : "#18484C")};
    margin-bottom: 8px;
    border-radius: 30px;
    &:first-child {
        transform: ${({ isModal }) =>
            isModal ? "translateY(13px) rotate(45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    &:nth-child(2) {
        transform: ${({ isModal }) =>
            isModal ? "translateX(-12px)" : "translateX(0px)"};
        opacity: ${({ isModal }) => (isModal ? "0" : "1")};
        transition: all 0.5s ease;
    }
    &:nth-child(3) {
        transform: ${({ isModal }) =>
            isModal ? "translateY(-13px) rotate(-45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    transition: all 0.5s ease;
    @media all and (max-width: 960px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "28px" : "38px") : "33px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
    @media all and (max-width: 540px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "22px" : "30px") : "28px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
`;
